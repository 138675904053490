<template>
    <div class="container auth-page">
        <div class="d-flex justify-content-center h-100">
            <div class="card">
                <div class="card-header">
                    <h3>Lấy lại mật khẩu</h3>
                    <p style="color: #fff">Nhập địa chỉ email của bạn và chúng tôi sẽ gửi cho bạn một liên kết để đặt lại mật khẩu của bạn.</p>
                </div>
                <div class="card-body">
                    <div class="input-group form-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-user"></i></span>
                        </div>
                        <b-form-input v-model="form.username" class="form-control" placeholder="Nhập địa chỉ email" />
                    </div>
                    <div class="row">
                        <div class="col-12" v-if="errors.username" >
                            <div class="alert alert-danger">
                                <span v-for="error in errors" :key="error[0]">{{error[0]}}<br/></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <b-button :disabled="loading" variant="primary" pill class="btn-block" @click="submit">
                            <b-spinner v-if="loading" small></b-spinner>
                            <span v-else>Lấy lại mật khẩu</span>
                        </b-button>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-center">
                        <a href="/#/login">Quay lại đăng nhập</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style type="text/css">
    @import '../../assets/css/auth.css';
</style>
<script>
    export default {
        data: function () {
            return {
                form: {},
                errors: {},
                loading: false
            };
        },
        methods: {
            submit: function (evt) {
                evt.preventDefault();
                this.loading = true;
                this.errors = {};
                this.$service.post('/site/forgot', this.form).then(response => {
                    if (response.data) {
                        this.$swal({
                            title: "Thành công",
                            text: "Yêu cầu khôi phục mật khẩu thành công, kiểm tra hòm mail của bạn và làm theo hướng dẫn, lưu ý kiểm tra cả spam và inbox!",
                            icon: 'success'
                        }).then(() => {
                            location.href = '#/login';
                        });
                    } else {
                        this.$swal({
                            title: "Cảnh báo",
                            text: "Hướng dẫn khôi phục mật khẩu đã được gửi vào email của bạn, xin hãy kiểm tra lại hòm mail hoặc đợi 5 phút để thử lại!",
                            icon: 'warning'
                        });
                    }
                }).catch(error => {
                    this.errors = error.response.data;
                }).finally(() => {
                    this.loading = false;
                });
            }
        }
    };
</script>